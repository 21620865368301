import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import styled, { css } from "styled-components";
import { motion } from "framer-motion";
import { ButtonMore } from "../Catalog/components/ListElem";

import phone from "../../assets/icons/preview/phone.svg";
import menu_adaptive from "../../assets/icons/preview/menu_adaptive.svg";
import menu_adaptive_close from "../../assets/icons/preview/menu_adaptive_close.svg";
import logo_new from "../../assets/icons/preview/logo_new.svg";
import logo_mobile from "../../assets/icons/preview/logo_mobile.svg";

const HeaderParent_ = styled.div`
  position: sticky;
  width: 100%;
  top: 0;
  opacity: 1;
  background: linear-gradient(
    105.65deg,
    rgb(49, 85, 73) 0%,
    rgb(34, 80, 64) 102.64%
  );
  transition-property: all;
  transition-timing-function: linear;
  transition-duration: 0.3s;
  z-index: 20;

  ${(props) =>
    props?.scrollDirection === "down" &&
    css`
      opacity: 0;
    `}
`;
const Header_ = styled.div`
  padding: 18px 0 13px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  color: white;
  border-bottom: 1px solid rgba(146, 176, 166, 0.2);

  a {
    color: white;
    text-decoration: none;
  }

  @media (max-width: 1300px) {
    display: none;
  }
`;
const NavList_ = styled.ul`
  margin-right: 53px;
  display: flex;
  align-items: center;

  li {
    margin-right: 30px;
    transition: all 0.3s linear;
    cursor: pointer;

    &:hover {
      color: rgb(247, 79, 56);
    }

    &.active {
      color: var(--text_green);
      border-bottom: 1px solid var(--text_green);
    }

    &:last-child {
      margin-right: 0;
    }

    &:nth-child(3) {
      margin-right: 0;
    }

    &:nth-child(4) {
      margin: 0 55px;
    }

    &:nth-child(5) {
      margin-right: 55px;
    }
  }
`;
const Location_ = styled.div`
  margin-right: 30px;
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 211px;
  white-space: nowrap;

  img {
    margin-right: 10px;
  }

  .text {
    font-size: 14px;
    line-height: 17px;
  }
`;
const Phone_ = styled.div`
  display: flex;
  padding: 10px;
  align-items: center;
  border: 1px solid #92b0a6;
  border-radius: 16px;
  font-family: "RfdRegular", sans-serif;
  white-space: nowrap;
  transition: all 0.3s linear;

  img {
    margin-right: 10px;
  }

  &:hover {
    transition: all 0.3s linear;
    background: var(--text_green);
  }

  @media (max-width: 1300px) {
    img {
      margin-right: 0;
    }
  }
`;
const HeaderMobile_ = styled.div`
  padding: 15px;
  position: relative;
  display: none;
  flex-direction: column;
  width: 100%;
  color: white;

  .header_logo {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .empty {
    width: 32px;
    height: 32px;
  }

  .icon {
    display: flex;
  }

  a {
    display: flex;
    height: 35px;
    align-self: center;
  }

  .bottom_drop {
    background: linear-gradient(
      105.65deg,
      rgb(49, 85, 73) 0%,
      rgb(34, 80, 64) 102.64%
    );
    padding: 0 0 20px 20px;
  }

  .drop_nav {
    li {
      font-family: RfdBold, sans-serif;
      font-weight: 600;
      font-size: 20px;
      line-height: 24px;

      &:first-child {
        margin-top: 20px;
      }

      &:not(:last-child) {
        margin-bottom: 20px;
      }
    }
  }

  @media (max-width: 1300px) {
    display: flex;
    .header_location {
      justify-content: center;
    }
  }

  @media (max-width: 470px) {
    .header_location {
      justify-content: start;
    }

    .icon {
      margin: 0 auto;

      img {
        width: 199px;
        height: 35px;
      }
    }

    ${Phone_} {
      border-radius: 14px;

      .number {
        font-size: 13px;
        line-height: 16px;
      }
    }

    ${Location_} {
      margin-right: 15px;
      max-width: fit-content;
      border-radius: 14px;

      img {
        margin-right: 5px;
        width: 34px;
        height: 35px;
      }

      .text {
        font-size: 13px;
        line-height: 16px;
      }
    }
  }
`;

const useScrollDirection = () => {
  const [scrollDirection, setScrollDirection] = useState(null);

  useEffect(() => {
    let lastScrollY = window.scrollY;
    const updateScrollDirection = () => {
      const scrollY = window.scrollY;
      const direction = scrollY > lastScrollY ? "down" : "up";
      if (
        direction !== scrollDirection &&
        (scrollY - lastScrollY > 1 || scrollY - lastScrollY < -1)
      ) {
        setScrollDirection(direction);
      }
      lastScrollY = scrollY > 0 ? scrollY : 0;
    };
    window.addEventListener("scroll", updateScrollDirection);
    return () => {
      window.removeEventListener("scroll", updateScrollDirection);
    };
  }, [scrollDirection]);
  return scrollDirection;
};
const Header = ({ setOpenCalculate, setOpenFeedback, scrollToContacts }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [openBurger, setOpenBurger] = useState(false);
  const scrollDirection = useScrollDirection();

  return (
    <HeaderParent_ scrollDirection={scrollDirection}>
      <div className="container">
        <Header_>
          <NavList_>
            <li
              onClick={() => navigate("/catalog")}
              className={
                window.location.href.includes("/catalog") ? "active" : ""
              }
            >
              Каталог продукции
            </li>
            <li
              onClick={() => navigate("/news")}
              className={window.location.href.includes("/news") ? "active" : ""}
            >
              Новости
            </li>
            <li onClick={() => setOpenCalculate()}>Калькулятор</li>
            <li onClick={() => navigate("/")}>
              <img src={logo_new} alt="logo" />
            </li>
            <li
              onClick={() => navigate("/projects")}
              className={
                window.location.href.includes("/projects") ? "active" : ""
              }
            >
              Проекты
            </li>
            <li
              onClick={() => {
                if (location.pathname !== "/") {
                  navigate("/");
                  setTimeout(() => {
                    scrollToContacts();
                  }, 300);
                } else scrollToContacts();
              }}
            >
              Контакты
            </li>
          </NavList_>
          <a href="tel:+74112211085" className="value">
            <Phone_>
              <img src={phone} alt="phone" />
              <div className="number">+7 (4112) 211085</div>
            </Phone_>
          </a>
        </Header_>
        <HeaderMobile_>
          <div className="header_logo">
            <div className="empty" />
            {openBurger && (
              <motion.div
                initial={{
                  opacity: 0,
                  transform: "scale(0.5) translateY(-50%)",
                  position: "absolute",
                  top: "50%",
                  left: 15,
                }}
                animate={{
                  opacity: 1,
                  transform: "scale(1) translateY(-50%)",
                  display: "flex",
                }}
                transition={{ delay: 0.3 }}
              >
                <img
                  src={menu_adaptive_close}
                  onClick={() => setOpenBurger(false)}
                  alt="menu_adaptive"
                />
              </motion.div>
            )}
            {!openBurger && (
              <motion.div
                initial={{
                  opacity: 0,
                  transform: "scale(0.5) translateY(-50%)",
                  position: "absolute",
                  top: "50%",
                  left: 15,
                }}
                animate={{
                  opacity: 1,
                  transform: "scale(1) translateY(-50%)",
                  display: "flex",
                }}
                transition={{ delay: 0.3 }}
              >
                <img
                  src={menu_adaptive}
                  onClick={() => setOpenBurger(true)}
                  alt="menu_adaptive"
                />
              </motion.div>
            )}
            <div className="icon" onClick={() => navigate("/")}>
              <img
                src={window.innerWidth > 475 ? logo_new : logo_mobile}
                alt="logo_mobile"
              />
            </div>
            <a href="tel:+74112211085" className="value">
              <Phone_>
                <img src={phone} alt="phone" />
              </Phone_>
            </a>
          </div>
          {openBurger && (
            <motion.div
              initial={{
                opacity: 0,
                maxHeight: 0,
                position: "absolute",
                top: 45,
                left: 0,
                width: "100%",
              }}
              animate={{ opacity: 1, maxHeight: 315 }}
              transition={{ delay: 0.3 }}
            >
              <div className="bottom_drop">
                <ul className="drop_nav">
                  <li
                    onClick={() => (navigate("/catalog"), setOpenBurger(false))}
                  >
                    Каталог продукции
                  </li>
                  <li onClick={() => (navigate("/news"), setOpenBurger(false))}>
                    Новости
                  </li>
                  <li
                    onClick={() => (setOpenCalculate(), setOpenBurger(false))}
                  >
                    Калькулятор
                  </li>
                  <li
                    onClick={() => (
                      navigate("/projects"), setOpenBurger(false)
                    )}
                  >
                    Проекты
                  </li>
                  <li
                    onClick={() => {
                      if (location.pathname !== "/") {
                        navigate("/");
                        setOpenBurger(false);
                        setTimeout(() => {
                          scrollToContacts();
                        }, 300);
                      } else {
                        setOpenBurger(false);
                        scrollToContacts();
                      }
                    }}
                  >
                    Контакты
                  </li>
                </ul>
                {/* <ButtonMore
                                    maxWidth={286}
                                    handleOpen={setOpenFeedback}
                                    label={"Заказать обратный звонок"}
                                    marginTop={"27px"}
                                />*/}
              </div>
            </motion.div>
          )}
        </HeaderMobile_>
      </div>
    </HeaderParent_>
  );
};

export default Header;
