import React, { useEffect, useState } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import requests from "../../axios/requests";

import { History } from "../News/News";
import { CatalogList } from "./components/CatalogList";

export const CategorySubcategories = () => {
  const params = useParams();
  const navigate = useNavigate();
  const crumb = useLocation();
  const [data, setData] = useState([]);

  useEffect(() => {
    if (params.id)
      requests
        .fetch_id_catalog(params.id)
        .then(
          (res) => (
            res.data?.forEach((elem) => (elem.size = "small")),
            setData(res.data)
          )
        )
        .catch((err) => console.error(err));
    else setData({});
  }, [params.id]);

  return (
    <CatalogList
      history={
        <History
          parts={[
            { path: "/", title: "Главная" },
            { path: "/catalog", title: "Каталог" },
            {
              title: crumb.state?.cat?.name,
            },
          ]}
        />
      }
      // title="Товары в категории"
      title={crumb.state?.cat?.name}
      data={data}
      currentPage={1}
      handleOpen={(id) => {
        const item = data.find((el) => el.uuid === id);
        navigate(`/catalog/${params.id}/${id}`, {
          state: { cat: crumb.state?.cat, cat2: item.name, id: id },
        });
      }}
    />
  );
};
