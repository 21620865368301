import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import logo_new from "../../../../assets/icons/preview/logo_new.svg";
import logo_mobile from "../../../../assets/icons/preview/logo_mobile.svg";

const FooterParent = styled.div`
  position: relative;
  overflow: hidden;
  background: var(--green);

  .black_back {
    position: absolute;
    left: -1px;
    top: 0;
    width: 100%;
    height: 70px;
    background: #1d1d1d;
    z-index: 0;
  }

  .green_back {
    position: absolute;
    left: -1px;
    bottom: 0;
    width: 100%;
    height: 50px;
    background: var(--green);
    z-index: -1;
  }

  @media (max-width: 728px) {
    margin-bottom: 50px;
  }
`;
const FooterStyle = styled.div`
  margin-top: 30px;
  padding: 50px 0 30px 0;
  width: calc(100% + 10px);
  background: var(--green);
  transform: rotate(-2deg);
  position: relative;

  .footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    transform: rotate(2deg);

    .logo {
      cursor: pointer;
    }

    .c {
      font-family: RdfRegular, sans-serif;
      color: white;
      font-size: 24px;
      line-height: 29px;
      @media (max-width: 728px) {
        display: none;
      }
    }

    @media (max-width: 728px) {
      justify-content: center;
    }
  }

  @media (max-width: 1350px) {
    padding: 50px 15px 30px 15px;
  }
`;

const Footer = ({ scrollToPreview }) => {
  const navigation = useNavigate();
  return (
    <FooterParent>
      <div className="black_back" />
      <FooterStyle>
        <div className="container">
          <foter className="footer">
            <div
              className="logo"
              onClick={() => [scrollToPreview(), navigation("/")]}
            >
              <img
                src={window.innerWidth > 768 ? logo_new : logo_mobile}
                alt="footer_logo"
              />
            </div>
            <div className="c">© Интехстрой</div>
          </foter>
        </div>
      </FooterStyle>
      <div className="green_back" />
    </FooterParent>
  );
};

export default Footer;
