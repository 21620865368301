import React, { useEffect, useState } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import styled from "styled-components";

import requests from "../../axios/requests";
import { History } from "../News/News";

import { Product } from "./components/Product";

import "./SubcategoryProducts.scss";

const SubcategoryProductsStyles = styled.div`
  background: #f3f3f3;
  padding: 15px 15px 0 15px;
`;

const ContentStyles = styled.ul`
  display: flex;
  flex-flow: row wrap;
  gap: 20px;

  @media (max-width: 1315px) {
    justify-content: center;
  }
`;

export const SubcategoryProducts = () => {
  const params = useParams();

  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const crumb = useLocation();

  useEffect(() => {
    if (params.id) {
      const url = ` https://api.its96.ru/api/v1/landing/category/${params.categoryid}/subcategory/${params.id}/items/`;
      fetch(url)
        .then((response) => {
          if (!response.ok) {
            throw new Error(
              "Network response was not ok: " + response.statusText
            );
          }
          return response.json();
        })
        .then((data) => {
          setData(data);
        })
        .catch((error) => {
          console.error("There was a problem with the fetch operation:", error);
        });
    }
  }, [params.id, params.categoryid]);

  return (
    <SubcategoryProductsStyles>
      <div className="container">
        <History
          parts={[
            { path: "/", title: "Главная" },
            { path: "/catalog", title: "Каталог" },
            {
              path: `/catalog/${params.categoryid}`,
              title: crumb.state?.cat?.name,
              state: { cat: crumb.state?.cat },
            },
            { title: crumb.state?.cat2 },
          ]}
        />
        <div className="catalog_page">
          <div className="h1">{crumb.state?.cat2}</div>
          <ContentStyles>
            {data.length > 0 &&
              data.map((elem) => (
                <Product
                  key={elem.index}
                  id={elem.index}
                  image={elem.images[0]}
                  name={elem.name}
                  price={elem.price}
                  type={elem.unit_of_measurement}
                  description={elem.description}
                  handleOpen={() =>
                    navigate(`/product/${elem.uuid}`, {
                      state: {
                        cat: crumb.state.cat,
                        cat2: crumb.state.cat2,
                        id: params.id,
                      },
                    })
                  }
                />
              ))}
          </ContentStyles>
        </div>
      </div>
    </SubcategoryProductsStyles>
  );
};
