import { useEffect, useRef, useState } from "react";
import { Route, Routes, useLocation } from "react-router-dom";

import { News as NewsCatalog } from "./pages/News/News";
import { CatalogCategories } from "./pages/Catalog/CatalogCategories";
import { CategorySubcategories } from "./pages/Catalog/CategorySubcategories";
import { SubcategoryProducts } from "./pages/Catalog/SubcategoryProducts";
import { ProductInfo } from "./pages/Catalog/ProductInfo";
import Preview from "./pages/Home/sections/Preview/Preview";
import Catalog from "./pages/Home/sections/Catalog/Catalog";
import About from "./pages/Home/sections/About/About";
import Maps from "./pages/Home/sections/Price/components/Maps";
import News from "./pages/Home/sections/News/News";
import Calculate from "./pages/Home/sections/Calculate/Calculate";
import Footer from "./pages/Home/sections/Footer/Footer";
import Projects from "./pages/Proects/Projects";
import NewsPage from "./pages/News/NewsPage";
import Header from "./pages/Header/Header";
import FeedbackModal from "./pages/Home/sections/FeedbackModal/FeedbackModal";
import CalculateModal from "./pages/Home/sections/Calculate/components/CalculateModal";
import HouseSlide from "./pages/Home/sections/HouseSlide/HouseSlide";
import ProjectPage from "./pages/Proects/ProjectPage";
import ScrollToTop from "./pages/ScrollToTop";

function App() {
  const [openFeedback, setOpenFeedback] = useState(false);
  const [openCalculate, setOpenCalculate] = useState(false);
  const location = useLocation();
  const refApp = useRef();
  const refContacts = useRef();
  const refPreview = useRef();

  let scrollToContacts = () => {
    if (typeof refContacts?.current !== "undefined")
      refContacts.current?.scrollIntoView({
        block: "center",
        behavior: "smooth",
      });
  };
  let scrollToPreview = () => {
    if (typeof refPreview?.current !== "undefined")
      refPreview.current?.scrollIntoView({ block: "end", behavior: "smooth" });
  };
  let scrollToTop = () => {
    refApp?.current?.scrollIntoView({ block: "start" });
  };

  useEffect(() => {
    refApp?.current?.scrollTo({ top: 0, left: 0 });
  }, [location.pathname]);
  return (
    <div className="app" ref={refApp}>
      <ScrollToTop />
      <Header
        setOpenCalculate={() => setOpenCalculate(true)}
        setOpenFeedback={() => setOpenFeedback(true)}
        scrollToContacts={scrollToContacts}
      />
      <Routes>
        <Route path={"/news"} element={<NewsCatalog />} />
        <Route path={"/projects"} element={<Projects />} />
        {/* рендерит список категорий каталога*/}
        <Route
          path={"/catalog"}
          element={<CatalogCategories key={1} scrollToTop={scrollToTop} />}
        />
        {/* рендерит список подкатегорий*/}
        <Route
          path={"/catalog/:id"}
          element={<CategorySubcategories key={2} />}
        />
        {/* рендерит именно продукт*/}
        <Route
          path={"/catalog/:categoryid/:id"}
          element={<SubcategoryProducts key={3} />}
        />
        <Route path={"/product/:id"} element={<ProductInfo key={4} />} />
        <Route path={"/news-page/:slug"} element={<NewsPage />} />
        <Route path={"/project-page/:id"} element={<ProjectPage />} />
        <Route
          path={"/"}
          element={[
            <Preview key={5} refPreview={refPreview} />,
            <Catalog key={6} />,
            <News key={7} />,
            <Calculate key={8} setOpen={() => setOpenCalculate(true)} />,
            <HouseSlide key={9} />,
            <About key={10} />,
            <Maps key={11} refContacts={refContacts} />,
          ]}
        />
      </Routes>
      <FeedbackModal
        open={openFeedback}
        setOpen={() => setOpenFeedback(false)}
      />
      <CalculateModal
        open={openCalculate}
        setOpen={() => setOpenCalculate(false)}
      />
      <Footer scrollToPreview={scrollToPreview} />
    </div>
  );
}

export default App;
