import React from "react";
import more from "../../../assets/icons/catalog/more.svg";
import styled, { css } from "styled-components";

const ButtonMoreStyled = styled.div`
  padding: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  height: 50px;
  color: white;
  background: linear-gradient(91.37deg, #f74f38 0%, #e3371f 100%);
  border-radius: 0 0 16px 16px;
  user-select: none;
  cursor: pointer;

  span {
    margin: 2px 10px 0 0;
  }

  ${(props) =>
    props.backColor &&
    css`
      background: ${props.backColor};
    `};
  ${(props) =>
    props.maxWidth &&
    css`
      max-width: ${props.maxWidth}px;
    `};
  ${(props) =>
    props.margin &&
    css`
      margin: 0 auto;
    `};
  ${(props) =>
    props.marginTop &&
    css`
      margin-top: ${props.marginTop};
    `};
  ${(props) =>
    props.disabled &&
    css`
      max-width: ${props.disabled}px;
      background: rgba(227, 55, 31, 0.5);
      cursor: auto;
    `};

  @media (max-width: 720px) {
    padding: 9px;
    border-radius: 0 0 14px 14px;
  }
`;
const Li = styled.li`
  margin-bottom: 20px;
  width: 100%;
  cursor: pointer;

  .name {
    margin: 20px auto;
    width: fit-content;
    font-family: RfdBold, sans-serif;
    font-weight: 600;
    font-size: 22px;
    line-height: 26px;
    color: #1d1d1d;

    @media (max-width: 720px) {
      margin: 15px auto;
      font-size: 16px;
      line-height: 19px;
    }
  }

  ${(props) =>
    props.size === "small" &&
    css`
      max-width: 420px;
    `}
  ${(props) =>
    props.size === "big" &&
    css`
      max-width: 640px;
    `}
  
  @media (max-width: 1320px) {
    max-width: 420px !important;
  }
`;
const PreviewStyled = styled.div`
  width: 100%;
  max-width: 640px;
  min-height: 373px;
  border-radius: 24px 24px 0 0;

  transition: background-size 0.5s ease-in;
  -moz-transition: background-size 0.5s ease-in;
  -ms-transition: background-size 0.5s ease-in;
  -o-transition: background-size 0.5s ease-in;
  -webkit-transition: background-size 0.5s ease-in;
  ${(props) =>
    props.size === "small" &&
    css`
      max-width: 420px;
    `}
  ${(props) =>
    props.src &&
    css`
      background: url(${props.src});
      background-repeat: no-repeat;
      background-size: 105% 105%;

      &:hover {
        background-size: 110% 110% !important;
      }
    `};

  @media (max-width: 1315px) {
    min-height: 320px;
  }
  @media (max-width: 450px) {
    min-height: 260px;
  }
`;
export const ButtonMore = ({
  label,
  maxWidth,
  margin,
  handleOpen,
  marginTop = "",
  backColor = "",
  disabled = false,
}) => {
  return (
    <ButtonMoreStyled
      maxWidth={maxWidth}
      margin={margin}
      marginTop={marginTop}
      disabled={disabled}
      backColor={backColor}
      onClick={() => {
        if (typeof handleOpen === "function") handleOpen();
      }}
    >
      <span>{label}</span>
      <img src={more} alt="more" />
    </ButtonMoreStyled>
  );
};
export const ListElem = ({
  label,
  src,
  handleOpen,
  id,
  size = "",
  page = null,
}) => {
  return (
    <Li size={size} page={page} onClick={() => handleOpen(id)}>
      <PreviewStyled size={size} src={src} />
      <ButtonMore label={"Подробнее"} handleOpen={() => handleOpen(id)} />
      <div className="name">{label}</div>
    </Li>
  );
};
