import styled from "styled-components";

export const CurrentHistory = styled.div`
  margin-bottom: 20px;
  display: flex;
  align-items: center;

  div {
    color: var(--text_green);
    cursor: pointer;

    &:last-child {
      color: #f74f38;
    }
  }

  img {
    margin: 0 10px;
  }
`;
