import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import requests from "../../axios/requests";
import { History } from "./News";
import { useNavigate, useParams } from "react-router-dom";

const NewsPage_ = styled.div`
  background: #f3f3f3;
  padding: 15px 15px 0 15px;

  .h1 {
    margin: 0 auto 50px auto;
    width: fit-content;
    max-width: 615px;
    text-align: center;
    font-family: RfdBold, sans-serif;
    font-weight: 700;
    font-size: 38px;
    line-height: 46px;
    color: #1d1d1d;
  }

  .text {
    max-width: 400px;
    width: 100%;
    margin: 20px auto;
    text-align: center;
    font-size: 18px;
    color: var(--green);
  }

  .border {
    margin: 80px 0 50px 0;
    width: 100%;
    height: 1px;
    background: var(--text_green);
  }
  @media (max-width: 820px) {
    .h1 {
      margin: 0 auto 30px auto;
      font-size: 22px;
      line-height: 26px;
    }
  }
`;
const Elem_ = styled.div`
  max-width: 800px;
  width: 100%;
  margin: 0 auto;
`;
const NewsImg_ = styled.div`
  margin: -20px auto 0 auto;
  max-width: 800px;
  width: 100%;
  height: 480px;
  border-radius: 24px;

  ${(props) =>
    props.src &&
    css`
      background: url("${props.src}") center center / cover;
    `};
  @media (max-width: 820px) {
    height: auto;
  }
`;
const MoreContent_ = styled.ul`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  min-height: 600px;

  li {
    margin: 0 20px 20px 0;
    max-width: 420px;
    width: 100%;
    transition: all 0.3s linear;
    cursor: pointer;

    .title {
      margin-bottom: 10px;
      font-family: RfdBold, sans-serif;
      font-weight: 600;
      font-size: 22px;
      line-height: 26px;
      color: var(--green);
    }

    .text {
      color: var(--text_green);
    }

    &:hover {
      transform: scale(1.01);
    }

    &:nth-child(3n + 3) {
      margin-right: 0;
    }
  }

  @media (max-width: 820px) {
    li {
      margin: 0 auto 10px auto;

      .title {
        text-align: center;
      }

      &:nth-child(3n + 3) {
        margin-right: auto;
      }
    }
  }
`;
const ImagePreview_ = styled.div`
  margin-bottom: 20px;
  border-radius: 24px;
  width: 100%;
  height: 300px;
  max-height: 300px;

  ${(props) =>
    props.src &&
    css`
      background: url("${props.src}") center center / cover;
    `}
`;

const NewsPage = () => {
  const params = useParams();
  const navigation = useNavigate();

  const [newsData, setNewsData] = useState([]);
  const [newsElem, setNewsElem] = useState({});

  let apiNews = () => {
    requests
      .fetchNews(1, 3)
      .then((res) => setNewsData(res.data))
      .catch((err) => console.error(err));
  };
  let apiIdNews = () => {
    requests
      .fetch_id_news(params.slug)
      .then((res) => setNewsElem(res.data))
      .catch((err) => console.error(err));
  };

  useEffect(() => {
    apiNews();
    apiIdNews();
  }, [params.slug]);
  console.log("newsData", newsData);
  console.log("newsElem", newsElem);

  return (
    <NewsPage_>
      <div className="container">
        <History
          parts={[
            { path: "/", title: "Главная" },
            { path: "/news", title: "Новости" },
            { title: newsElem?.title },
          ]}
        />
        <Elem_>
          <div className="h1">{newsElem?.title}</div>
          <NewsImg_ src={newsElem?.image} />
          <div className="text">{newsElem?.text}</div>
          <div dangerouslySetInnerHTML={{ __html: newsElem?.post }}></div>
        </Elem_>
        <div className="border" />
        <div className="h1">Возможно вам будет интересно</div>
        <MoreContent_>
          {newsData?.results?.length > 0 &&
            newsData.results.map((elem) => (
              <li
                key={elem.slug}
                onClick={() => navigation(`/news-page/${elem.slug}`)}
              >
                <ImagePreview_ src={elem.image} />
                <div className="title">{elem.title}</div>
                <div className="text">{elem.text}</div>
              </li>
            ))}
        </MoreContent_>
      </div>
    </NewsPage_>
  );
};

export default NewsPage;
