import React from "react";
import styled from "styled-components";
import { ButtonMore } from "../../../../Catalog/components/ListElem";

const Maps_ = styled.div`
  padding: 80px 0;
  background: #f3f3f3;

  .group_buttons {
    display: flex;
    gap: 20px;
    /* div:first-child {
      margin-right: 20px;
    } */
  }

  @media (max-width: 1090px) {
    padding: 40px 0;
  }
  @media (max-width: 728px) {
    padding: 40px 15px;
    .group_buttons {
      flex-direction: column;
    }
  }
`;
const H1_ = styled.div`
  margin-bottom: 20px;
  font-family: RfdBold, sans-serif;
  font-weight: 700;
  font-size: 38px;
  line-height: 46px;
  color: #1d1d1d;

  @media (max-width: 728px) {
    margin-bottom: 10px;
    font-size: 28px;
    line-height: 34px;
  }
`;
const Info_ = styled.div`
  margin-bottom: 50px;
  max-width: 630px;
  width: 100%;
  color: var(--text_green);

  @media (max-width: 728px) {
    margin-bottom: 20px;
    max-width: 344px;
    font-size: 13px;
    line-height: 16px;
  }
`;
const Contacts_ = styled.div`
  margin-bottom: 40px;
  display: flex;
  align-items: center;

  .item {
    margin-right: 40px;
    height: 90px;
    min-width: 320px;
    display: flex;
    flex-direction: column;
    color: #1d1d1d;

    a {
      text-decoration: none;
    }

    .label {
      margin-bottom: 15px;
      font-size: 22px;
      line-height: 26px;
    }

    .value {
      margin-top: auto;
      color: var(--green);
      font-family: RdfRegular, sans-serif;
      font-size: 24px;
      /* line-height: 29px;*/
    }

    .email_group,
    .phones_group {
      div,
      a {
        font-size: 24px;
        line-height: 29px;
        color: var(--green);
        white-space: nowrap;
      }
    }

    .phones_group {
      display: flex;
      flex-direction: column;
    }

    &:last-child {
      margin-right: 0;
    }
  }

  @media (max-width: 1090px) {
    flex-direction: column;
    align-items: inherit;

    .item {
      margin-right: 0;
      margin-bottom: 20px;
      height: 55px;

      .label {
        margin-bottom: 5px;
        font-size: 18px;
        line-height: 22px;
      }

      .value {
        font-size: 24px;
        line-height: 29px;
      }

      &:nth-child(2) {
        height: 80px;
      }
    }
  }
  @media (max-width: 470px) {
    .item {
      .value {
        font-size: 20px;
        line-height: 24px;
      }

      .email_group {
        div {
          font-size: 20px;
          line-height: 24px;
          white-space: inherit;
        }
      }

      .phones_group {
        a {
          font-size: 20px;
          line-height: 24px;
          white-space: inherit;
        }
      }
    }
  }
  @media (max-width: 470px) {
    .item {
      &:nth-child(1) {
        height: 60px;
      }
      &:nth-child(2) {
        height: 80px;
      }
    }
  }
`;
const Maps = ({ refContacts }) => {
  let watsaappSend = () => {
    window.open("https://api.whatsapp.com/send?phone=79247652504", "_blank");
    /*window.open("https://wa.me/send?phone=79247652504", "_blank")*/
  };
  let watsaappSend_2 = () => {
    window.open("https://api.whatsapp.com/send?phone=79245652504", "_blank");
    /*window.open("https://wa.me/send?phone=79245652504", "_blank")*/
  };
  let watsaappSend_3 = () => {
    window.open(`https://t.me/intekhstroj`, "_blank");
  };
  return (
    <Maps_>
      <div className="container">
        <H1_>Свяжитесь с нами</H1_>
        <Info_>
          Если у вас есть вопросы или вы хотите обсудить сотрудничество -
          пишите, звоните, и мы сами все расскажем.
        </Info_>
        <Contacts_>
          <div className="item" ref={refContacts}>
            <div className="label">Наш телефон:</div>
            <a href="tel:+74112211085" className="value">
              +7 (4112) 211085
            </a>
            <div className="phones_group">
              {/*  <a
                                href="tel:+89245652504"
                                className="value"
                            >
                                +8 (924) 5652504 (Металлком)
                            </a>*/}
            </div>
          </div>
          <div className="item">
            <div className="label">Почта:</div>
            <div className="email_group">
              <div>info@its96.ru (Интехстрой)</div>
              <div>252504@its96.ru (Металлком)</div>
            </div>
          </div>
          <div className="item">
            <div className="label">Адрес:</div>
            <div className="value">г. Якутск, ул. Леваневского, 3</div>
          </div>
        </Contacts_>
        <iframe
          id="map"
          frameBorder="no"
          width={"100%"}
          height={350}
          src="https://widgets.2gis.com/widget?type=firmsonmap&options=%7B%22pos%22%3A%7B%22lat%22%3A62.055112116493916%2C%22lon%22%3A129.76982116699222%2C%22zoom%22%3A16%7D%2C%22opt%22%3A%7B%22city%22%3A%22yakutsk%22%7D%2C%22org%22%3A%227037402698745664%22%7D"
        />
        <div className="group_buttons">
          <div>
            <ButtonMore
              label={"Написать в WhatsApp Интехстрой"}
              maxWidth={351}
              handleOpen={() => watsaappSend()}
              marginTop={"30px"}
              backColor={"#315549"}
            />
            <ButtonMore
              label={"Написать в Телеграм Интехстрой"}
              maxWidth={351}
              handleOpen={() => watsaappSend_3()}
              marginTop={"30px"}
              backColor={"#315549"}
            />
          </div>

          <ButtonMore
            label={"Написать в WhatsApp МеталлКом"}
            maxWidth={351}
            handleOpen={() => watsaappSend_2()}
            marginTop={"30px"}
            backColor={"#315549"}
          />
        </div>
      </div>
    </Maps_>
  );
};

export default Maps;
